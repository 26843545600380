import './App.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {THEMES} from "./utils/themes";
import {useSelector} from "react-redux";
import {selectUI} from "./redux/features/ui/ui-slice";
import {Route, Routes} from "react-router";
import IndexPage from "./pages/index/index-page";

function App() {

    const {theme} = useSelector(selectUI);

    return (
        <ThemeProvider theme={theme === 'dark' ? THEMES.dark : THEMES.light}>
            <CssBaseline enableColorScheme={true}/>
            <Routes>
                <Route element={<IndexPage/>} path="/" exact={true}/>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
