import {configureStore} from "@reduxjs/toolkit";
import uiReducer from "./../features/ui/ui-slice";
import authReducer from "./../features/auth/auth-slice";
import {CONSTANTS} from "../../utils/constants";

const token = localStorage.getItem(CONSTANTS.BERRI_USER_TOKEN) ?
    JSON.parse(localStorage.getItem(CONSTANTS.BERRI_USER_TOKEN)) : null;

const authData = localStorage.getItem(CONSTANTS.BERRI_USER_AUTH_DATA) ?
    JSON.parse(localStorage.getItem(CONSTANTS.BERRI_USER_AUTH_DATA)) : null;

const theme = localStorage.getItem(CONSTANTS.BERRI_THEME_VARIANT) ?
    JSON.parse(localStorage.getItem(CONSTANTS.BERRI_THEME_VARIANT)) : 'dark';

const store = configureStore({
    reducer: {
        ui: uiReducer,
        auth: authReducer
    },
    preloadedState: {
        auth: {
            authData,
            token
        },
        ui: {theme}
    },
    devTools: true
});

export default store;
