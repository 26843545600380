import {createSlice} from "@reduxjs/toolkit";
import {CONSTANTS} from "../../../utils/constants";

const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        theme: localStorage.getItem(CONSTANTS.EMIRATES_BDS_SERVICES_THEME_VARIANT) ? localStorage.getItem(CONSTANTS.EMIRATES_BDS_SERVICES_THEME_VARIANT): 'light',
        drawerOpen: false,
        sidebarOpen: true,
        sidebarMobileDrawer: false
    },
    reducers: {
        toggleDrawer: (state, action) => {
            state.drawerOpen = action.payload;
        },
        toggleSidebar: (state, action) => {
            state.sidebarOpen = action.payload;
        },
        toggleMobileSidebar: (state, action) => {
            state.sidebarMobileDrawer = action.payload;
        },
        toggleTheme: (state) => {
            state.theme = state.theme === 'dark' ? 'light': 'dark';
            localStorage.setItem(
                CONSTANTS.EMIRATES_BDS_SERVICES_THEME_VARIANT, state.theme === 'dark' ?
                    JSON.stringify('light'):
                    JSON.stringify('dark'))
        }
    },
});

export const selectUI = state => state.ui;
export const UI_ACTION_CREATORS = {
    toggleDrawer: uiSlice.actions.toggleDrawer,
    toggleSidebar: uiSlice.actions.toggleSidebar,
    toggleMobileSidebar: uiSlice.actions.toggleMobileSidebar,
    toggleTheme: uiSlice.actions.toggleTheme,
};
export default uiSlice.reducer;
