import {Box, Button, Card, CardContent, Grid, LinearProgress, Stack, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import * as yup from "yup";
import "yup-phone";
import {useNavigate} from "react-router-dom";
import {motion} from "framer-motion";
import {useDispatch, useSelector} from "react-redux";
import {AUTH_ACTION_CREATORS, selectAuth} from "../../redux/features/auth/auth-slice";
import {useSnackbar} from "notistack";
import {useCallback} from "react";
import {selectUI} from "../../redux/features/ui/ui-slice";

const SignUpForm = ({onOpen, setOpenConfirmationDialog}) => {

    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const showMessage = useCallback((message, options) => {
        enqueueSnackbar(message, options);
    }, [enqueueSnackbar]);

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "+233",
            city: "",
            password: "",
            hearAboutUs: "",
            agreedTerms: false
        },
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: yup.object().shape({
            firstName: yup.string().required('First name required'),
            lastName: yup.string().required('Last name required'),
            email: yup.string().email('Enter valid email').required('First name required'),
            phone: yup.string().phone("Enter a valid phone").required('First name required'),
            city: yup.string().required('City required'),
            password: yup.string().required('Password required'),
            hearAboutUs: yup.string().required('Field required')
        }),
        onSubmit: (values, {resetForm, setSubmitting}) => {
            dispatch(AUTH_ACTION_CREATORS.register({values, resetForm, navigate, showMessage, setSubmitting, setOpenConfirmationDialog}));
        }
    });

    const {theme} = useSelector(selectUI);
    const {authLoading} = useSelector(selectAuth);

    return (
        <Card
            initial={{opacity: 0, x: -1000}}
            animate={{x: 0, opacity: 1, type: "spring", bounce: 1, stiffness: 250}}
            transition={{duration: 1}}
            component={motion.div}
            sx={{
                borderBottomRightRadius: 16,
                borderTopLeftRadius: 16,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 0,
                backgroundColor: theme === "dark" ? "rgba(33,33,33,0.4)" : "rgba(255,255,255,0.1)",
                backdropFilter: "blur(10px)",
                zIndex: 1000
            }}>
            {authLoading && <LinearProgress variant="query" color="secondary" /> }
            <CardContent>
                <form autoComplete="off" onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <Typography
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            transition={{duration: 1}}
                            whileHover={{translateX: 50}}
                            component={motion.p}
                            variant="body1"
                            sx={{color: "text.primary"}}>
                            Sign up to get started
                        </Typography>
                        <Typography
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            transition={{duration: 1}}
                            whileHover={{translateX: 50}}
                            component={motion.p}
                            variant="body2"
                            sx={{color: "text.secondary"}}>
                            All fields * are required
                        </Typography>

                        <Box>
                            <Grid container={true} spacing={2}>
                                <Grid item={true} xs={12} md={6}>
                                    <TextField
                                        value={formik.values.firstName}
                                        name="firstName"
                                        id="firstName"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        margin="dense"
                                        size="small"
                                        type="text"
                                        variant="outlined"
                                        placeholder="Enter first name"
                                        label="First Name"
                                        error={Boolean(formik.touched.firstName && formik.errors.firstName)}
                                        helperText={formik.touched.firstName && formik.errors.firstName}
                                        fullWidth={true}
                                        required={true}
                                        InputLabelProps={{shrink: true}}
                                    />
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <TextField
                                        value={formik.values.lastName}
                                        name="lastName"
                                        id="lastName"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        margin="dense"
                                        size="small"
                                        type="text"
                                        variant="outlined"
                                        placeholder="Enter last name"
                                        label="Last Name"
                                        InputLabelProps={{shrink: true}}
                                        error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                                        helperText={formik.touched.lastName && formik.errors.lastName}
                                        fullWidth={true}
                                        required={true}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box>
                            <TextField
                                value={formik.values.email}
                                name="email"
                                id="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                margin="dense"
                                size="small"
                                type="email"
                                variant="outlined"
                                placeholder="Enter email"
                                label="Email"
                                error={Boolean(formik.touched.email && formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                fullWidth={true}
                                required={true}
                                InputLabelProps={{shrink: true}}
                            />
                        </Box>
                        <Box>
                            <TextField
                                value={formik.values.phone}
                                name="phone"
                                id="phone"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                margin="dense"
                                size="small"
                                type="tel"
                                variant="outlined"
                                placeholder="Enter phone"
                                label="Phone"
                                error={Boolean(formik.touched.phone && formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                                fullWidth={true}
                                required={true}
                                InputLabelProps={{shrink: true}}
                            />
                        </Box>
                        <Box>
                            <TextField
                                value={formik.values.password}
                                name="password"
                                id="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                margin="dense"
                                size="small"
                                type="password"
                                variant="outlined"
                                placeholder="Enter password"
                                label="Password"
                                error={Boolean(formik.touched.password && formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                fullWidth={true}
                                required={true}
                                InputLabelProps={{shrink: true}}
                            />
                        </Box>
                        <Box>
                            <TextField
                                value={formik.values.city}
                                name="city"
                                id="city"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                margin="dense"
                                size="small"
                                type="text"
                                variant="outlined"
                                placeholder="Enter city"
                                label="City"
                                error={Boolean(formik.touched.city && formik.errors.city)}
                                helperText={formik.touched.city && formik.errors.city}
                                fullWidth={true}
                                required={true}
                                InputLabelProps={{shrink: true}}
                            />
                        </Box>
                        <Box>
                            <TextField
                                value={formik.values.hearAboutUs}
                                name="hearAboutUs"
                                id="hearAboutUs"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                margin="dense"
                                size="small"
                                type="text"
                                variant="outlined"
                                placeholder="Where did you hear from us?"
                                label="Where did you hear from us?"
                                error={Boolean(formik.touched.hearAboutUs && formik.errors.hearAboutUs)}
                                helperText={formik.touched.hearAboutUs && formik.errors.hearAboutUs}
                                fullWidth={true}
                                required={true}
                                multiline={true}
                                minRows={3}
                                InputLabelProps={{shrink: true}}
                            />
                        </Box>
                        <Stack spacing={2} alignItems="center">
                            <Box>
                                <Button
                                    component={motion.button}
                                    whileTap={{
                                        scale: 0.8,
                                        transition: {duration: 0.3}
                                    }}
                                    whileHover={{
                                        scale: 1.1,
                                        transition: {
                                            repeat: Infinity,
                                            repeatType: "reverse",
                                            duration: 0.3,
                                            originX: 0
                                        }

                                    }}
                                    type="submit"
                                    color="secondary"
                                    variant="text"
                                    disabled={authLoading}
                                    sx={{
                                        borderBottomRightRadius: 16,
                                        borderTopLeftRadius: 16,
                                        borderTopRightRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        backgroundColor: "light.secondary",
                                        textTransform: "none"
                                    }} size="large">Get Me Started</Button>
                            </Box>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    Have an account?
                                </Typography>
                                <Button
                                    component={motion.button}
                                    whileHover={{
                                        scale: 1.05,
                                        transition: {
                                            repeat: Infinity,
                                            repeatType: "reverse",
                                            duration: 0.3,
                                            originX: 0
                                        }
                                    }}
                                    size="large"
                                    onClick={onOpen}
                                    sx={{textTransform: "none", color: "secondary.main"}}>
                                    Login
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </form>
            </CardContent>
        </Card>
    )
}

export default SignUpForm;
