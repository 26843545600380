import {Card, CardContent, Stack, Typography} from "@mui/material";
import {motion} from "framer-motion";

const Feature = ({feature}) => {
    const {title, icon, description} = feature;
    return (
        <Card
            initial={{opacity: 0, y: -1000}}
            animate={{
                y: 0,
                opacity: 1
            }}
            transition={{
                duration: 2
            }}
            component={motion.div}
            sx={{backgroundColor: "background.default"}} elevation={0}>
            <CardContent>
                <Stack spacing={2}>
                    <Stack
                        initial={{opacity: 0, x: 50}}
                        animate={{opacity: 1, x: 0}}
                        transition={{duration: 0.5, delay: 0.1}}
                        whileHover={{
                            x: 10,
                            transition:{duration: 0.5, delay: 0.1},
                        }}
                        component={motion.div}
                        direction="row">
                        {icon}
                    </Stack>
                    <Typography
                        initial={{opacity: 0, x: 50}}
                        animate={{opacity: 1, x: 0}}
                        transition={{duration: 2, delay: 1}}
                        whileHover={{
                            x: 10,
                            transition:{duration: 0.5, delay: 0.2}
                        }}
                        component={motion.p}
                        variant="h5"
                        sx={{color: "text.primary"}}>
                        {title}
                    </Typography>
                    <Typography
                        initial={{opacity: 0, x: 50}}
                        animate={{opacity: 1, x: 0}}
                        transition={{duration: 2, delay: 1}}
                        whileHover={{
                            x: 10,
                            transition:{duration: 0.5, delay: 0.2}
                        }}
                        component={motion.p}
                        variant="body1"
                        sx={{color: "text.secondary"}}>
                        {description}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default Feature;
