import {Button, Dialog, DialogContent, Stack, Typography} from "@mui/material";
import {AnimatePresence, motion} from "framer-motion";
import {useSelector} from "react-redux";
import {selectUI} from "../../redux/features/ui/ui-slice";


const container = {
    hidden: {
        opacity: 0,
        x: '-100vw',
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            staggerDirection: -1,
            staggerChildren: 0.3,
            stiffness: 250,
            bounce: 1,
            when: "beforeChildren",
            delay: 0.1,
            duration: 0.3
        }
    }
};
const item = {
    hidden: {
        opacity: 0,
        x: '-100vw',
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            stiffness: 250,
            bounce: 1,
            duration: 0.3
        }
    },
    tap: {scale: 0.8, transition: {duration: 0.3}},
    hover: {scale: 1.2, transition: {duration: 0.3, repeat: Infinity, repeatType: "mirror"}}
};

const LoginDialog = ({open, onClose}) => {

    const {theme} = useSelector(selectUI);

    return (
        <AnimatePresence
            initial={false}
            onExitComplete={onClose}
            mode="popLayout">
            {open && (
                <Dialog
                    key="modal"
                    sx={{
                        backgroundColor: theme === "dark" ? "rgba(33,33,33,0.2)" : "rgba(255,255,255,0.2)",
                        backdropFilter: "blur(2px)",
                    }}
                    component={motion.div}
                    animate={{
                        opacity: 1,
                        y: 0,
                        transition: {delay: 0.1, duration: 0.3, stiffness: 250, bounce: 0.3, type: "spring"}
                    }}
                    exit={{
                        opacity: 0,
                        y: '100vh',
                        transition: { duration: 1, stiffness: 150, bounce: 0.5, type: "spring"}
                    }}
                    initial={{opacity: 0, y: '-100vh'}}
                    open={open} onClose={onClose}>
                    <DialogContent>
                        <Stack
                            animate="visible"
                            initial="hidden"
                            variants={container}
                            component={motion.div} direction="column" spacing={2}>
                            <Typography
                                variants={item}
                                component={motion.h3}
                                align="center"
                                variant="h3" sx={{color: "secondary.main"}}>
                                Notice
                            </Typography>

                            <Typography
                                variants={item}
                                component={motion.p}
                                align="center"
                                variant="body1" sx={{color: "text.primary"}}>
                                Please, if you already have an account, don’t worry. We are currently updating our
                                system.
                                Try again later. For inquiries, contact: baahdaniel.br@gmail.com
                            </Typography>

                            <Stack component={motion.div} variants={item} direction="row" justifyContent="flex-end">
                                <Button
                                    onClick={onClose}
                                    variants={item}
                                    component={motion.button}
                                    whileTap="tap"
                                    whileHover="hover"
                                    color="secondary"
                                    variant="text"
                                    fullWidth={false}
                                    sx={{
                                        borderBottomRightRadius: 16,
                                        borderTopLeftRadius: 16,
                                        borderTopRightRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        backgroundColor: "light.secondary",
                                        textTransform: "none"
                                    }} size="large">Close</Button>
                            </Stack>
                        </Stack>
                    </DialogContent>
                </Dialog>
            )}
        </AnimatePresence>
    )
}

export default LoginDialog;
