import {AppRegistrationOutlined, ScheduleOutlined} from "@mui/icons-material";

const FEATURES = [
    {
        title: "Set your own schedule",
        description: "Get haircuts and other hair services whenever and wherever you want. That means you are in control of your own time - because with Dropcut, you’re in charge.",
        icon:
            <ScheduleOutlined
                color="secondary"
                sx={{
                    borderBottomRightRadius: 16,
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    backgroundColor: "light.secondary",
                    padding: 0.6,
                    fontSize: 32
                }}
            />
    },
    {
        title: "Signing up is easy",
        description: "Sign up today and you will be connected to your personalized barber in no time. Plus, signing up takes less than a minute. Don’t wait to start taking your handsomeness to the next level.",
        icon:
            <AppRegistrationOutlined
                color="secondary"
                sx={{
                    borderBottomRightRadius: 16,
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    backgroundColor: "light.secondary",
                    padding: 0.6,
                    fontSize: 32
                }}
            />
    }
]

export const BERRI_DATA = {FEATURES};
