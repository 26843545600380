import {Box, Button, CardMedia, Container, Grid, Stack, Typography} from "@mui/material";
import SignUpForm from "../../components/shared/sign-up-form";
import banner from "./../../assets/images/banner.jpg";
import Feature from "../../components/shared/feature";
import {BERRI_DATA} from "../../utils/data";
import {motion} from "framer-motion";
import {useDispatch, useSelector} from "react-redux";
import {selectUI, UI_ACTION_CREATORS} from "../../redux/features/ui/ui-slice";
import {DarkModeOutlined, LightModeOutlined} from "@mui/icons-material";
import {useState} from "react";
import LoginDialog from "../../components/dialogs/login-dialog";
import SignUpConfirmationDialog from "../../components/dialogs/sign-up-confirmation-dialog";

const IndexPage = () => {

    const {theme} = useSelector(selectUI);
    const dispatch = useDispatch();

    const [showLogin, setShowLogin] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    return (
        <Box sx={{py: 4}}>
            <Box>
                <Container>
                    <Grid spacing={4} justifyContent="space-between" container={true} alignItems="flex-start">
                        <Grid xs={12} md="auto" item={true}>
                            <Typography
                                initial={{opacity: 0, x: 50}}
                                animate={{opacity: 1, x: 0}}
                                transition={{duration: 0.5, delay: 0.2}}
                                whileHover={{
                                    x: 10,
                                    transition: {duration: 0.5, delay: 0.2, repeat: Infinity, repeatType: "mirror"},
                                    rotateZ: 180
                                }}
                                component={motion.p}
                                align="center"
                                variant="h4"
                                sx={{color: "secondary.main"}}>
                                Dropcut
                            </Typography>
                        </Grid>
                        <Grid xs={12} md="auto" item={true}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Button
                                    initial={{opacity: 0, x: '-50vw'}}
                                    animate={{opacity: 1, x: 0}}
                                    transition={{duration: 2, delay: 0.2}}
                                    whileHover={{
                                        scale: 1.1,
                                        transition: {
                                            duration: 0.3,
                                            repeat: Infinity,
                                            repeatType: "reverse",
                                            bounce: 1,
                                            stiffness: 150
                                        }
                                    }}
                                    whileTap={{scale: 0.8}}
                                    component={motion.button}
                                    fullWidth={true}
                                    color="secondary"
                                    sx={{
                                        borderBottomRightRadius: 16,
                                        borderTopLeftRadius: 16,
                                        borderTopRightRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        backgroundColor: "light.secondary",
                                        textTransform: "none"
                                    }} variant="text" size="large">Sign Up Now</Button>

                                {theme === 'dark' ? (
                                    <Box
                                        initial={{opacity: 0, x: 50}}
                                        animate={{opacity: 1, x: 0}}
                                        transition={{duration: 2, delay: 0.2}}
                                        whileHover={{
                                            x: 10,
                                            transition: {duration: 0.5, delay: 0.2},
                                            rotateZ: 180
                                        }}
                                        component={motion.div}
                                        direction="row" alignItems="center" justifyContent="center">
                                        <LightModeOutlined
                                            color="secondary"
                                            onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                                            sx={{
                                                padding: 0.6,
                                                fontSize: 36,
                                                cursor: 'pointer',
                                                borderBottomLeftRadius: 0,
                                                borderBottomRightRadius: 16,
                                                borderTopLeftRadius: 16,
                                                borderTopRightRadius: 16,
                                                backgroundColor: 'light.secondary'
                                            }}/>
                                    </Box>
                                ) : (
                                    <Box
                                        initial={{opacity: 0, x: 50}}
                                        animate={{opacity: 1, x: 0}}
                                        transition={{duration: 2, delay: 0.2}}
                                        whileHover={{
                                            x: 10,
                                            transition: {duration: 0.5, delay: 0.2},
                                            rotateZ: 180
                                        }}
                                        component={motion.div}
                                        direction="row" alignItems="center" justifyContent="center">
                                        <DarkModeOutlined
                                            color="secondary"
                                            onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                                            sx={{
                                                padding: 0.6,
                                                fontSize: 36,
                                                cursor: 'pointer',
                                                borderBottomLeftRadius: 0,
                                                borderBottomRightRadius: 16,
                                                borderTopLeftRadius: 16,
                                                borderTopRightRadius: 16,
                                                backgroundColor: 'light.secondary'
                                            }}/>
                                    </Box>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>

                    <Box sx={{position: "relative", py: 12}}>
                        <Grid container={true} spacing={4}>
                            <Grid xs={12} md={8} item={true}>
                                <Typography
                                    initial={{opacity: 0, x: -50}}
                                    animate={{opacity: 1, x: 0}}
                                    transition={{duration: 2, delay: 1}}
                                    whileHover={{
                                        x: -100,
                                        transition: {duration: 0.5, delay: 0.2}
                                    }}
                                    component={motion.p}
                                    align="center"
                                    variant="h4"
                                    sx={{color: "text.primary"}}>
                                    Get quality and personalized haircuts without stepping foot in a barbershop.
                                </Typography>
                            </Grid>
                            <Grid xs={12} md={4} item={true}>
                                <Box sx={{position: {xs: "block", lg: "absolute"}}}>
                                    <SignUpForm
                                        setOpenConfirmationDialog={setShowConfirmationDialog}
                                        onOpen={() => setShowLogin(true)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
            <Box>
                <CardMedia
                    component="img"
                    sx={{
                        display: {xs: "none", lg: "block"},
                        backgroundColor: theme === "dark" ? "rgba(33, 33, 33, 0.6)" : "rgba(255, 255, 255, 0.6)",
                        maxHeight: {lg: '90vh'}
                    }} src={banner}/>
            </Box>
            <Box sx={{pt: {xs: 0, lg: 8}}}>
                <Container>
                    <Grid container={true}>
                        {BERRI_DATA.FEATURES.map((feature, index) => {
                            return (
                                <Grid key={index} item={true} xs={12} lg={6}>
                                    <Feature feature={feature}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Box>
            <LoginDialog
                onClose={() => setShowLogin(false)}
                open={showLogin}
            />
            <SignUpConfirmationDialog
                onClose={() => setShowConfirmationDialog(false)}
                open={showConfirmationDialog}
            />
        </Box>
    )
}

export default IndexPage;
