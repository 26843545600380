import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import authAPI from "../../../api/auth";
import {CONSTANTS} from "../../../utils/constants";

const initialState = {
    authLoading: false,
    authMessage: null,
    authError: null,
    token: null,
    authData: null
};

const login = createAsyncThunk('auth/login',
    async (
        {values, navigate, resetForm, showMessage, setSubmitting},
        {rejectWithValue}) => {
        try {
            setSubmitting(true);
            const response = await authAPI.login(values);
            localStorage.setItem(CONSTANTS.BERRI_USER_TOKEN, JSON.stringify(response.data.token));
            localStorage.setItem(CONSTANTS.BERRI_USER_AUTH_DATA, JSON.stringify(response.data.data));
            navigate(`/dashboard`);
            resetForm();
            showMessage(response.data.message, {variant: 'success'});
            setSubmitting(false);
            return response.data;
        } catch (e) {
            const {message} = e.response.data;
            showMessage(message, {variant: 'error'});
            setSubmitting(false);
            return rejectWithValue(message);
        }
    });


const verifyAccount = createAsyncThunk('auth/verifyAccount',
    async (
        {values, resetForm, setSubmitting, navigate, showMessage, token},
        {rejectWithValue}) => {
        try {
            setSubmitting(true);
            const response = await authAPI.verifyAccount(values, token);
            localStorage.setItem(CONSTANTS.BERRI_USER_TOKEN, JSON.stringify(response.data.token));
            localStorage.setItem(CONSTANTS.BERRI_USER_AUTH_DATA, JSON.stringify(response.data.data));

            navigate(`/`);
            resetForm();
            showMessage(response.data.message, {variant: 'success'});
            setSubmitting(false);
            return response.data;
        } catch (e) {
            const {message} = e.response.data;
            showMessage(message, {variant: 'error'});
            setSubmitting(false);
            return rejectWithValue(message);
        }
    });


const getProfile = createAsyncThunk('auth/getProfile',
    async ({token, navigate}, {rejectWithValue}) => {
        try {
            const response = await authAPI.getProfile(token);
            return response.data;
        } catch (e) {
            const {message} = e.response.data;
            if (message === 'jwt expired') {
                navigate('/auth/login');
                localStorage.removeItem(CONSTANTS.BERRI_USER_TOKEN);
                localStorage.removeItem(CONSTANTS.BERRI_USER_AUTH_DATA);

            }
            return rejectWithValue(message);
        }
    });

const updateProfile = createAsyncThunk('auth/updateProfile',
    async ({token, resetForm, setSubmitting, ...rest}, {rejectWithValue}) => {
        try {
            const response = await authAPI.updateProfile(token, {...rest});
            resetForm();
            setSubmitting(false);
            localStorage.setItem(CONSTANTS.BERRI_USER_TOKEN, JSON.stringify(response.data.token));
            localStorage.setItem(CONSTANTS.BERRI_USER_AUTH_DATA, JSON.stringify(response.data.data));
            return {data: response.data.data, token: response.data.token};
        } catch (e) {
            const {message} = e.response.data;
            return rejectWithValue(message);
        }
    })

const verifyOTP = createAsyncThunk('auth/verifyOTP',
    async ({values, token, navigate, resetForm, showMessage}, {rejectWithValue}) => {
        try {
            const response = await authAPI.verifyOTP(values, token);
            localStorage.setItem(CONSTANTS.BERRI_USER_TOKEN, JSON.stringify(response.data.token));
            localStorage.setItem(CONSTANTS.BERRI_USER_AUTH_DATA, JSON.stringify(response.data.data));
            navigate(`/dashboard`);
            resetForm();
            showMessage(response.data.message, {variant: 'success'});
            return {data: response.data.data, token: response.data.token};
        } catch (e) {
            const {message} = e.response.data;
            showMessage(message, {variant: 'error'});
            return rejectWithValue(message);
        }
    });

const resendOTP = createAsyncThunk('auth/resendOTP',
    async ({
               values,
               navigate,
               resetForm,
               showMessage
           }, {rejectWithValue}) => {
        try {
            const response = await authAPI.resendOTP(values);
            navigate(`/auth/otp/${response.data.token}/verify`);
            resetForm();
            showMessage(response.data.message, {variant: 'success'});
            localStorage.setItem(CONSTANTS.BERRI_USER_TOKEN, JSON.stringify(response.data.token));
            localStorage.setItem(CONSTANTS.BERRI_USER_AUTH_DATA, JSON.stringify(response.data.data));
            return {data: response.data.data, token: response.data.token};
        } catch (e) {
            const {message} = e.response.data;
            showMessage(message, {variant: 'error'});
            rejectWithValue(message);
        }
    });


const register = createAsyncThunk(
    'auth/register',
    async (
        {values, resetForm, showMessage, setSubmitting, setOpenConfirmationDialog},
        {rejectWithValue}) => {
        try {
            setSubmitting(true);
            const response = await authAPI.register(values);
            resetForm();
            setSubmitting(false);
            setOpenConfirmationDialog(true);
            return response.data;
        } catch (e) {
            const {message} = e.response.data;
            showMessage(message, {variant: 'error'});
            setSubmitting(false);
            return rejectWithValue(message);
        }
    });


const logout = createAsyncThunk('auth/logout', async ({navigate}, thunkAPI) => {
    try {
        localStorage.removeItem(CONSTANTS.BERRI_USER_AUTH_DATA);
        localStorage.removeItem(CONSTANTS.BERRI_USER_TOKEN);
        navigate('/auth/login');
    } catch (e) {
        return thunkAPI.rejectWithValue(e.message);
    }
});

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.token = null;
            state.authData = null;
            state.authLoading = true;
            state.authError = null;
            state.authMessage = null;
        }).addCase(login.fulfilled, (state, action) => {
            state.token = action.payload.token;
            state.authData = action.payload.data;
            state.authLoading = false;
            state.authError = null;
            state.authMessage = action.payload.authMessage;
        }).addCase(login.rejected, (state, action) => {
            state.token = null;
            state.authData = null;
            state.authLoading = false;
            state.authError = action.payload;
            state.authMessage = action.payload;
        }).addCase(getProfile.pending, (state) => {
            state.authLoading = true;
            state.authError = null;
        }).addCase(getProfile.fulfilled, (state, action) => {
            state.authLoading = false;
            state.authError = null;
            state.authData = action.payload.data;
            state.token = action.payload.token;
            state.authMessage = action.payload.message;
        }).addCase(getProfile.rejected, (state, action) => {
            state.authLoading = false;
            state.authError = action.payload;
            state.authData = null;
            state.token = null;
        }).addCase(verifyOTP.pending, (state) => {
            state.authLoading = true;
            state.authError = null;
        }).addCase(verifyOTP.fulfilled, (state, action) => {
            state.authLoading = false;
            state.authError = null;
            state.authData = action.payload.data;
            state.token = action.payload.token;
            state.authMessage = action.payload.message;
        }).addCase(verifyOTP.rejected, (state, action) => {
            state.authLoading = false;
            state.authError = action.payload;
            state.authData = null;
            state.token = null;
        }).addCase(verifyAccount.pending, (state) => {
            state.authLoading = true;
            state.authError = null;
        }).addCase(verifyAccount.fulfilled, (state, action) => {
            state.authLoading = false;
            state.authError = null;
            state.authMessage = action.payload.message;
        }).addCase(verifyAccount.rejected, (state, action) => {
            state.authLoading = false;
            state.authError = action.payload;
            state.authData = null;
            state.token = null;
        }).addCase(resendOTP.pending, (state) => {
            state.authLoading = true;
            state.authError = null;
        }).addCase(resendOTP.fulfilled, (state, action) => {
            state.authLoading = false;
            state.authError = null;
            state.authData = action.payload.data;
            state.token = action.payload.token;
            state.authMessage = action.payload.message;
        }).addCase(resendOTP.rejected, (state, action) => {
            state.authLoading = false;
            state.authError = action.payload;
            state.authData = null;
            state.token = null;
        }).addCase(updateProfile.pending, (state) => {
            state.token = null;
            state.authData = null;
            state.authLoading = true;
            state.authError = null;
            state.authMessage = null;
        }).addCase(updateProfile.fulfilled, (state, action) => {
            state.token = action.payload.token;
            state.authData = action.payload.data;
            state.authLoading = false;
            state.authError = null;
            state.authMessage = action.payload.authMessage;
        }).addCase(updateProfile.rejected, (state, action) => {
            state.token = null;
            state.authData = null;
            state.authLoading = false;
            state.authError = action.payload;
            state.authMessage = action.payload;
        }).addCase(register.pending, (state) => {
            state.token = null;
            state.authData = null;
            state.authLoading = true;
            state.authError = null;
            state.authMessage = null;
        }).addCase(register.fulfilled, (state, action) => {
            state.token = action.payload.token;
            state.authLoading = false;
            state.authError = null;
        }).addCase(register.rejected, (state, action) => {
            state.token = null;
            state.authData = null;
            state.authLoading = false;
            state.authError = action.payload;
        }).addCase(logout.pending, (state) => {
            state.token = null;
            state.authData = null;
            state.authLoading = true;
            state.authError = null;
            state.authMessage = null;
        }).addCase(logout.fulfilled, (state, action) => {
            state.token = null;
            state.authLoading = false;
            state.authError = null;
        }).addCase(logout.rejected, (state, action) => {
            state.token = null;
            state.authData = null;
            state.authLoading = false;
            state.authError = action.payload;
        })
    }
});

export const AUTH_ACTION_CREATORS = {
    login,
    getProfile,
    verifyOTP,
    resendOTP,
    updateProfile,
    verifyAccount,
    register,
    logout
};
export const selectAuth = state => state.auth;
export default authSlice.reducer;
